import { useAppSelector } from '@/hooks';
import { EnsuranceBaseInfo, EnsuranceOffer } from '@/actions/types/EnsuranceOffer';
import dayjs from 'dayjs';
import { GetIsDevMode } from '@/forms/shared/IsDevMode';

const KEY_STORED_TABLE = 'ens_stored_comparedata';

const storeSavedTables = (data) => {
  try {
    const storeData = { data, storedAt: dayjs().toJSON() };
    localStorage.setItem(KEY_STORED_TABLE, JSON.stringify(storeData));
  } catch (e) {
    console.error(e);
  }
};

const loadSavedTables = () => {
  try {
    const storeData = JSON.parse(localStorage.getItem(KEY_STORED_TABLE) || '{}');
    if (!storeData || !storeData.storedAt) return null;

    const storedAt = dayjs(storeData.storedAt);
    const ageInMinutes = Math.abs(storedAt.diff(dayjs(), 'minute', false));

    if (ageInMinutes > 60) {
      localStorage.removeItem(KEY_STORED_TABLE);
      return null;
    }

    if (GetIsDevMode()) console.log(`Gespeicherte Tabellendaten sind ${ageInMinutes} Minutes alt`);
    return storeData.data;
  } catch (e) {
    console.error(e);
  }
};

window.ensLoadSavedTables = loadSavedTables;

const defualtState = {
  tableId: null as number | null,
  ensuranceBaseInfo: {} as { [ensName: string]: EnsuranceBaseInfo } | null,
  selectedNames: [],
  visibleNames: [],
  visibleNamesCount: 3,
  visibleNamesCountOverride: null as number | null,
  visibleNamesPosition: 0,

  canMoveNext: false,
  canMovePrev: false,

  compareModeAvailable: false,
  compareMode: false,
  compareData: {} as CompareTableGroups,
  compareTariffLimit: 3,
  loading: false,
  devicemode: '',
  groupInfos: {},
  groupPoints: {},

  savedTables: loadSavedTables() || {},
};

export default function counter(state = defualtState, action): typeof defualtState {
  let newState = { ...state };

  if (action.type === 'ENS_FIELDS_LOADED' && action.mode === 'default') {
    return { ...state, visibleNamesCount: action.values.compareTariffLimit };
  } else if (action.type === 'COMPARE_ENS_TOGGLE_SELECTED') {
    let selNames = state.selectedNames;
    if (!selNames.includes(action.name)) {
      selNames = [...selNames, action.name].filter((v, i, a) => a.indexOf(v) === i);
    } else {
      selNames = selNames.filter((o) => o !== action.name);
    }

    newState = {
      ...state,
      selectedNames: selNames,
    };
  } else if (action.type === 'SINGLE_ENS_COMPARE') {
    newState = {
      ...state,
      selectedNames: Array.isArray(action.name) ? action.name : [action.name],
    };
  } else if (action.type === 'COMPARE_ENS_ACTIVE_MODE') {
    newState = {
      ...state,
      compareMode: true,
    };
  } else if (action.type === 'COMPARE_RESET_MODE') {
    newState = {
      ...state,
      compareMode: false,
    };
    if (action.resetSelection) {
      newState.selectedNames = [];
    }
  } else if (action.type === 'COMPARE_ENS_LOADED') {
    if (newState.savedTables[action.tableId] && !action.data) {
      newState = { ...newState, ...newState.savedTables[action.tableId] };
    } else {
      const newTable: any = {};
      newTable.tableId = action.tableId;
      newTable.displayName = action.displayName;
      newTable.compareData = action.data;
      newTable.groupInfos = action.groupInfos;
      newTable.devicemode = action.devicemode;
      newTable.groupPoints = action.groupPoints;
      newTable.ensuranceBaseInfo = action.ensuranceBaseInfo || {};

      newState = { ...newState, savedTables: { ...newState.savedTables, [action.tableId]: newTable }, ...newTable };
      storeSavedTables(newState.savedTables);
    }

    newState.loading = false;
  } else if (action.type === 'COMPARE_ENS_CLEAR') {
    return { ...defualtState, compareData: state.compareData };
  } else if (action.type === 'COMPARE_ENS_LOADING') {
    newState.loading = true;
  } else if (action.type === 'COMPARE_MOVE_PREV') {
    if (newState.visibleNamesPosition === 0) return state;
    newState.visibleNamesPosition -= 1;
  } else if (action.type === 'COMPARE_MOVE_NEXT') {
    if (
      newState.visibleNamesPosition ===
      newState.selectedNames.length - (newState.visibleNamesCountOverride || newState.visibleNamesCount)
    )
      return state;
    newState.visibleNamesPosition += 1;
  } else if (action.type === 'COMPARE_TABLE_ELEMENT_COUNT') {
    newState.visibleNamesCountOverride = action.payload;
  } else {
    return state;
  }

  const visibleCount = newState.visibleNamesCountOverride || newState.visibleNamesCount;

  newState.compareModeAvailable = newState.selectedNames.length >= 1 && !newState.compareMode;

  newState.visibleNames = newState.selectedNames.slice(
    newState.visibleNamesPosition,
    newState.visibleNamesPosition + visibleCount
  );
  newState.canMovePrev = newState.visibleNamesPosition > 0;
  newState.canMoveNext = newState.visibleNamesPosition < newState.selectedNames.length - visibleCount;

  return newState;
}

export const useCanSelectMoreCompareItems = () => {
  return useAppSelector((state) => state.ensCompare.selectedNames.length < state.ensfields.compareTariffLimit);
};

export const useCanToggleEnsuranceForCompare = (ensuranceItem: EnsuranceOffer) => {
  const canSelectMoreCompareItems = useCanSelectMoreCompareItems();
  const isAlreadySelected = useAppSelector((state) => state.ensCompare.selectedNames.includes(ensuranceItem.name));

  return (canSelectMoreCompareItems && !ensuranceItem.failed) || isAlreadySelected;
};
