import React from 'react';
import { Text } from '@mantine/core';

import Rechtsbelehrungcontainer from '../shared/style/Rechtsbelehrungcontainer';
import { useAppSelector } from '@/hooks';

function Rechtsbelehrung() {
  const offerRechtsbelehrung = useAppSelector((state) => state.checkout.offer?.rechtsbelehrungen) || [];

  const externRechtsbelehrung = useAppSelector((state) => state.ensfields.ens_rechtsbelehrung_url);

  return (
    <>
      {externRechtsbelehrung ? null : (
        <>
          <h3
            style={{
              fontSize: `${14 * 1.5}px`,
              margin: 0,
            }}>
            Rechtsbelehrung
          </h3>

          <Rechtsbelehrungcontainer>
            <ul>
              {offerRechtsbelehrung.map((o) => (
                <li key={o}>{o}</li>
              ))}
            </ul>
          </Rechtsbelehrungcontainer>
        </>
      )}
      <Text>
        Mit Klick auf &quot;Zahlungspflichtig beantragen&quot; bestätige ich, die{' '}
        {externRechtsbelehrung ? (
          <a
            target='_blank'
            href={externRechtsbelehrung}
            rel='noreferrer'>
            Rechtsbelehrung
          </a>
        ) : (
          'Rechtsbelehrung'
        )}
        , sowie die dem Vertrag zugrunde liegenden aktuellen Versicherungsbedingungen gelesen zu haben und akzeptiere
        diese.
      </Text>
    </>
  );
}

export default Rechtsbelehrung;
