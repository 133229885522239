import { useAppDispatch, useAppSelector, useDispatchCallback, useDispatchEffect } from '@/hooks';
import { closeOverlay, OverlayControlVerboseSettings } from '@/processes/overlay/overlayReducer';
import { Box, Button, ButtonProps, createStyles, Group, Loader, Space, Title, Stack } from '@mantine/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HookedTextInput } from '../shared/HookedInputs';
import { changeUserInput } from '@/actions/form';
import { updateVoucher } from '@/actions/data/updateVoucher';

import { sizes as buttonSizes } from '@mantine/core/esm/Button/Button.styles';
import { EnsIcon } from '../shared/AvailalbeEnsIcons';
import { ServerCustomProductSelection } from '@/reducer/customProducts';
import { type CustomProductNames } from '@/reducer/customProducts/customProducts.types';
import { useIsXs } from '@/forms/shared/EnsContainerObserver';
import VoucherBox from '../ensuranceList/VoucherBox';
import { ButtonLink } from '@unserkunde/enscompare-components';
import { useBrowsersBack } from '@/features/BrowserNavigationHandler';
import { dispatchWhenCompareMode, onResetCompareMode } from '@/actions/compare';
import { navigateEnsuranceSelected } from '@/actions/navigation';
import { InitialVoucherLoader } from './InitialVoucherLoader';
import { EnsPriceInfo } from '../shared/pricing/EnsPriceInfo';
import { useGlobalBackAction } from '@/processes/shared/GlobalBackContext';
import requestOffers from '@/actions/data/requestOffers';

const useStyles = createStyles((theme, props?: { hasActiveVoucher?: boolean; isXs?: boolean }) => ({
  card: {
    transform: 'scale(1)',
    '&:hover': {
      transform: 'scale(1.02)',
      transition: 'transform 0.2s ease',
    },
  },

  flexRowStackOnXs: {
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  flexRowStackOnXsReverse: {
    flexDirection: 'column-reverse',
    alignItems: 'stretch',
  },

  gridTwoC: {
    display: 'grid',
    gridTemplateColumns: `repeat(${!props?.hasActiveVoucher || props.isXs ? 1 : 2},1fr)`,
    gap: theme.spacing.xs,
  },

  voucherInfo: {
    justifySelf: 'end',
  },

  productImageAlignBottom: {
    img: {
      objectPosition: 'bottom',
    },
  },

  productImageDisplay: {
    // add a shaodw
    filter: 'drop-shadow(0 0 0.75rem #00000010);',
    img: {
      border: `1px solid ${theme.colors.gray[3]}`,
      backgroundColor: theme.colors.gray[1],
      borderRadius: '1000px',
      aspectRatio: '1/1',
      objectFit: 'cover',
      height: '100px',
      position: 'relative',
      display: 'flex',
      ':after': {
        content: '"?"',
        alignSelf: 'center',
        justifySelf: 'center',
      },
    },
  },

  productItemContainer: {
    transition: 'all 0.2s ease',
    ':hover': {
      transform: 'scale(1.02)',
    },
  },
}));

const ContinueButton = (props: ButtonProps & { autoFocus?: boolean; onContinued?: () => void }) => {
  const { theme } = useStyles(undefined);
  const isLoading = useAppSelector((state) => state.ensuranceList.loading);

  const defaultCode = useAppSelector((state) => state.ensfields.ens_fallback_vouchercode);
  const currentCode = useAppSelector((state) => state.userData.voucherCode);

  const dispatch = useAppDispatch();
  const gotoResults = useCallback(async () => {
    if (props.onContinued) props.onContinued();

    if (!currentCode) {
      await dispatch(changeUserInput('voucherCode', defaultCode));
      await dispatch(updateVoucher({ skipUpdateEnsurance: true }));
      dispatch(
        requestOffers(true, true, true, {
          skipResetCompareMode: true,
          skipVoucherOverlay: true,
          skipDefaultUserDataLoading: true,
        })
      );
    }
    dispatch(closeOverlay());
  }, [props, currentCode, dispatch, defaultCode]);

  const [ref, setRef] = useState<HTMLButtonElement>(null);

  useEffect(() => {
    if (!props.autoFocus) return;
    ref?.focus();
  }, [props.autoFocus, ref]);

  return (
    <Button
      ref={setRef}
      data-testid='btn-ens-continue-loading-overlay'
      color={isLoading ? 'gray.2' : 'secondary'}
      onClick={gotoResults}
      leftIcon={
        isLoading && (
          <Loader
            color={'gray'}
            size={theme.fn.size({ size: 'md', sizes: buttonSizes }).height / 2}
          />
        )
      }
      {...props}>
      Weiter zu den Angeboten
    </Button>
  );
};

const useVoucherLoadedWatcher = () => {
  const [disabled, setDisabled] = useState(false);
  const disable = useCallback(() => setDisabled(true), []);

  const loadedVoucher = useAppSelector((state) => state.voucher.voucher);
  const isLoading = useAppSelector((state) => state.voucher.loading);

  useDispatchEffect(
    (dispatch) => {
      if (isLoading || !loadedVoucher?.valid || disabled) return;

      dispatch(requestOffers(true, true, true, { skipResetCompareMode: true, skipVoucherOverlay: true }));
    },
    [isLoading, loadedVoucher?.valid, disabled]
  );

  return [disable] as const;
};

const LoadingPageContent = () => {
  const loadingProgressInfo = useAppSelector((state) => state.ensuranceList.loadingInfo);

  const [totalOfferLoad, setTotalOfferLoad] = useState(0);
  useEffect(() => {
    if (!totalOfferLoad && loadingProgressInfo?.total) {
      setTotalOfferLoad(parseInt(loadingProgressInfo?.total));
    }
  }, [loadingProgressInfo.total, totalOfferLoad]);

  const isLoading = useAppSelector((state) => state.ensuranceList.loading);

  const currentVoucherInfo = useAppSelector((state) => state.voucher);

  const isXs = useIsXs();

  const { classes } = useStyles({ hasActiveVoucher: currentVoucherInfo?.valid, isXs });

  const [disableReloadWatch] = useVoucherLoadedWatcher();

  return (
    <Box>
      <Group
        position='apart'
        noWrap
        className={isXs ? classes.flexRowStackOnXsReverse : undefined}>
        <Title
          order={4}
          mb='md'>
          Wir laden jetzt Angebot {isLoading ? loadingProgressInfo?.current || 0 : totalOfferLoad} von{' '}
          {totalOfferLoad || 100}
        </Title>
        <ContinueButton
          autoFocus={true}
          onContinued={disableReloadWatch}
        />
      </Group>

      <Title order={6}>Hast du einen Gutscheincode - gib ihn hier ein:</Title>

      <Box className={classes.gridTwoC}>
        <HookedTextInput
          rightSection={
            currentVoucherInfo.loading ? (
              <Loader size='xs' />
            ) : currentVoucherInfo?.valid ? (
              <EnsIcon
                color='green'
                icon='MdOutlineCheck'
              />
            ) : null
          }
          disableWrap
          placeholder='Dein Gutscheincode'
          field='voucherCode'
        />

        {currentVoucherInfo?.valid && (
          <VoucherBox
            className={classes.voucherInfo}
            patternLeft>
            Gutschein eingelöst
          </VoucherBox>
        )}
      </Box>

      <Space h='lg' />

      <ProductList />
    </Box>
  );
};

export const ProductListImage = (props: {
  item: ServerCustomProductSelection<CustomProductNames>;
  productKey: CustomProductNames;
}) => {
  const { classes, cx } = useStyles(undefined);

  const customProducts = useAppSelector((state) => state.customProducs.custom_products);
  const customProduct = customProducts[props.productKey];

  const images = customProduct?.imageUrls;

  const voucherAmount = useAppSelector((state) => state.voucher.voucher?.amount);

  const firstImage = images.length > 0 ? images[0] : undefined;
  const firstImageSource = typeof firstImage === 'string' ? firstImage : firstImage?.url;

  const imageClass = useMemo(() => {
    let imgClass: keyof typeof classes = null;
    switch (typeof firstImage === 'string' ? null : firstImage?.align) {
      case 'bottom':
        imgClass = 'productImageAlignBottom';
        break;
      default:
        imgClass = null;
    }
    return imgClass;
  }, [firstImage]);

  if (!images) return null;
  if (images.length === 0) return null;

  return (
    <Stack
      className={classes.productItemContainer}
      mt={'md'}
      style={{ alignItems: 'center' }}
      spacing={'xs'}>
      <Box className={cx(classes.productImageDisplay, imageClass ? classes[imageClass] : undefined)}>
        <img
          src={firstImageSource}
          alt={'Produktbild von: ' + props.item.displayName}
        />
      </Box>
      <Title
        ta={'center'}
        mb={'sm'}
        mt={0}
        order={6}>
        {props.item.displayName}

        {props.productKey === 'cashbackVoucher' ? (
          <>
            <br />
            In Höhe von
            {voucherAmount?.unit === 'cent' && (
              <> {EnsPriceInfo.once(parseInt(voucherAmount.value) / 100).displayFormat()}€</>
            )}
            {voucherAmount?.unit === 'percent' && <> {parseInt(voucherAmount.value)}%</>}
          </>
        ) : (
          <>
            <br />
            &nbsp;
          </>
        )}
      </Title>
    </Stack>
  );
};

export const ProductList = () => {
  const defaultServerProductSelection = useAppSelector((state) => state.customProducs.defaultServerProductSelection);

  const currentVoucherInfo = useAppSelector((state) => state.voucher);
  const defaultCode = useAppSelector((state) => state.ensfields.ens_fallback_vouchercode);

  if (
    !currentVoucherInfo?.voucher?.valid ||
    !defaultServerProductSelection ||
    Object.keys(defaultServerProductSelection).length === 0
  )
    return null;

  return (
    <>
      <Title
        order={5}
        c={'gray.7'}
        mt='lg'>
        Es erwarten dich viele tolle Vorteile bei Antragsstellung, zum Beispiel:
      </Title>
      <Group
        mb='lg'
        spacing={'md'}>
        {(Object.keys(defaultServerProductSelection || {}) as CustomProductNames[])
          .map((key) => ({ key, item: defaultServerProductSelection[key] as ServerCustomProductSelection<typeof key> }))
          .filter((c) => c.item)
          .filter((c) => c.key !== 'cashbackVoucher' || currentVoucherInfo?.voucher?.code !== defaultCode)
          .map((customProduct) => (
            <ProductListImage
              key={customProduct.key}
              productKey={customProduct.key}
              item={customProduct.item}
            />
          ))}
      </Group>
      {/* <List>
        {(Object.keys(defaultServerProductSelection || {}) as CustomProductNames[])
          .map((key) => ({ key, item: defaultServerProductSelection[key] as ServerCustomProductSelection<typeof key> }))
          .filter((c) => c.item)
          .map((customProduct) => (
            <List.Item key={customProduct.key}>{customProduct.item.displayName}</List.Item>
          ))}
      </List> */}
    </>
  );
};

export const LoadingEnsPage = () => {
  const isXs = useIsXs();

  const { classes } = useStyles(undefined);

  const onGoBack = useDispatchCallback((dispatch) => {
    dispatch(dispatchWhenCompareMode(onResetCompareMode(false), navigateEnsuranceSelected.prev()));
    dispatch(closeOverlay());
  });

  useBrowsersBack(onGoBack);
  const hasGlobalBackButton = useGlobalBackAction(onGoBack);

  return (
    <Box mt='sm'>
      <InitialVoucherLoader />
      <LoadingPageContent />

      <Group
        className={isXs ? classes.flexRowStackOnXs : undefined}
        noWrap
        position='apart'
        mt='sm'>
        {hasGlobalBackButton ? (
          <div />
        ) : (
          <ButtonLink
            onClick={onGoBack}
            wrap={false}>
            Zurück
          </ButtonLink>
        )}
        <ContinueButton />
      </Group>
      <Space h='lg' />
    </Box>
  );
};

export const LoadingEnsPageOverlaySettings: OverlayControlVerboseSettings = {
  component: LoadingEnsPage,
  settings: {
    hideNavigation: true,
  },
};
