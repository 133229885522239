//import './wdyr';

import './sentry';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

import './features/NumeralSetup';
import {
  addInlineStyle,
  enrichtWithUniqueId,
  IndexElementsContext,
  IndexElementsType,
  InlineStylesProvider,
} from './indexElements';

import './ViteDynamicContentLoader';

// @ts-ignore
import styles from './styles/index.scss?inline';

// @ts-ignore
import globalStyles from './styles/globals.scss?inline';

import { EnsErrorBoundary } from './forms/shared/EnsErrorBoundary';
import { createEnsEmotionCache } from './indexAppEmotionCache';
import { DeepPartial } from '@reduxjs/toolkit';
import { storeCreator } from './store';

const mountingCache = {};

function ensInitialize(
  domRootId?: string,
  ensOptions?: Partial<EnsOptions>,
  ensPreset?: RootState['ensPreset']['ensPreset']
) {
  domRootId = domRootId || window.ensDOMRoot || 'ensurance_view_root';
  ensOptions = ensOptions || {};
  ensPreset = ensPreset || {};

  // Unmount old container if its exists
  if (mountingCache[domRootId]) {
    mountingCache[domRootId]();
  }

  const outerDomRoot = document.getElementById(domRootId) as HTMLDivElement;
  outerDomRoot.innerHTML = '';
  enrichtWithUniqueId(outerDomRoot);

  const domRoot = document.createElement('div');
  outerDomRoot.appendChild(domRoot);
  enrichtWithUniqueId(domRoot);

  const shadowDom = domRoot.attachShadow({ mode: 'open' });

  const emotionCacheRoot = enrichtWithUniqueId(document.createElement('div'));
  const reactRootElement = enrichtWithUniqueId(document.createElement('div'));
  const stylesRoot = enrichtWithUniqueId(document.createElement('div'));

  shadowDom.appendChild(stylesRoot);
  shadowDom.appendChild(emotionCacheRoot);
  shadowDom.appendChild(reactRootElement);

  addInlineStyle(styles, stylesRoot);
  addInlineStyle(globalStyles, stylesRoot);

  const root = createRoot(reactRootElement);

  const emotionCache = createEnsEmotionCache(emotionCacheRoot);

  const indexElementsContextValue: IndexElementsType = {
    emotionCacheRoot,
    reactRootElement,
    stylesRoot,
    outerDomRoot: domRoot,
  };

  const storeStartValue: DeepPartial<RootState> = {
    ensOptions: ensOptions,
    ensPreset: {
      ensPreset,
      ensPresetData: null,
    },
    indexElements: Object.keys(indexElementsContextValue).reduce(
      (acc, key) => ({ ...acc, [key]: indexElementsContextValue[key].id }),
      {}
    ),
  };
  const store = storeCreator(storeStartValue);

  const EnsMain = () => {
    return (
      <StrictMode>
        <IndexElementsContext.Provider value={indexElementsContextValue}>
          <InlineStylesProvider>
            <EnsErrorBoundary>
              <App
                mantineProviderProps={{
                  defaultProps: { Portal: { target: shadowDom } },
                  emotionCache: emotionCache,
                }}
                store={store}
              />
            </EnsErrorBoundary>
          </InlineStylesProvider>
        </IndexElementsContext.Provider>
      </StrictMode>
    );
  };

  root.render(<EnsMain />);

  if (window.ensRootClass) {
    window.ensRootClass.split(' ').forEach((cl) => reactRootElement.classList.add(cl));
  }

  const unmountCallback = () => {
    root.unmount();
    outerDomRoot.innerHTML = '';
  };
  mountingCache[domRootId] = unmountCallback;

  return unmountCallback;
}

if (!window.ensSkipAutoinitialize) {
  ensInitialize(null, window.ensOptions, window?.ensPreset);
}

window.ensInitialize = ensInitialize;
document.dispatchEvent(new CustomEvent('ensLoaded', {}));

/* window.ensRerenderWithApiSource = (apiSourceUrl: string) => {
  root.unmount();

  window.ensOverrideHostUrl = apiSourceUrl;
  disableEnsFieldPreload();
  resetStore();

  root = createRoot(reactRootElement);
  root.render(<EnsMain />);
};
 */
