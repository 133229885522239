import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Loader } from '@mantine/core';
import { changeUserInput } from '../../actions/form';
import { HookedCheckboxRow, HookedSelect, HookedTextInput } from '@/forms/shared/HookedInputs';
import { EnsIcon } from '@/forms/shared/AvailalbeEnsIcons';
import InstanceModeSwitch from '@/forms/shared/Switches/InstanceModeSwitch';
import { useAppSelector, useDynamicFields } from '@/hooks';
import DynamicEntryQuestion from '../shared/DynamicEntryQuestion';

const ZusammenfassungFields = (props) => {
  const ensfields = useAppSelector((state) => state.ensfields);

  const customQuestions = useDynamicFields('zusammenfassung');

  const mappedGesellschaften: { value: string; label: string }[] = useMemo(
    () =>
      ensfields.gesellschaften && Array.isArray(ensfields.gesellschaften)
        ? ensfields.gesellschaften.map((g) => ({ value: g.Value, label: g.Text || ' ' }))
        : [],
    [ensfields?.gesellschaften]
  );

  return (
    <>
      {customQuestions.map((e, i) => (
        <DynamicEntryQuestion
          setting={e}
          key={i}
        />
      ))}
      <HookedTextInput
        rightSection={
          props.voucherInfo.loading ? (
            <Loader size='xs' />
          ) : props?.voucherInfo?.valid ? (
            <EnsIcon
              color='gray'
              icon='MdOutlineCheck'
            />
          ) : null
        }
        label='Gutscheincode'
        field='voucherCode'
        placeholder='Haben Sie einen Gutscheincode?'
      />

      <InstanceModeSwitch enable={'bike'}>
        <HookedSelect
          field='hausratVersicherungBei'
          label='Hausratsversicherung bei'
          helpText={'Optionale Angabe'}
          values={mappedGesellschaften}
          clearable
        />
      </InstanceModeSwitch>

      {!props.userData.hausratVersicherungBei || props.userData.hausratVersicherungBei === '00000' ? null : (
        <HookedCheckboxRow
          title='Hausratversicherung digital verwalten'
          description='Hiermit können Sie Ihre Hausratversicherung ebenfalls über unser Kundenportal verwalten lassen. Als Willkommensgeschenk erhalten Sie zusätzliche 30,-€ Cashback von uns nach erfolgreicher Übertragung. Bitte beachten Sie, dass wir dann zukünftig Ihr neuer Ansprechpartner zum Thema Hausratversicherung sind.'
          field='hausratMitBetreuen'
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  options: state.options.options || [],
  offer: state.checkout.offer,
  ensfields: state.ensfields,
  devicekey: state.devicemode.devkey,
  voucherInfo: state.voucher,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeInput:
      (field, selector = null, bikeId = null) =>
      (...changeProps) =>
        dispatch(changeUserInput(field, (selector || ((o) => o))(...changeProps), bikeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ZusammenfassungFields);
