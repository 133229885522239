import { EnsuranceOffer } from '@/actions/types/EnsuranceOffer';
import { useCompareHeaderStyles } from './CompareTableHeaderStyles';
import { useAppSelector } from '@/hooks';
import { Box, Card, Flex, Text } from '@mantine/core';
import React from 'react';
import { EnsIcon } from '@/forms/shared/AvailalbeEnsIcons';
import { EnsPriceInfo } from '@/forms/shared/pricing/EnsPriceInfo';
import { useExternCalcNotice } from '@/forms/ensuranceList/EnsuranceItem';

export const CompareTableHeaderItemMiddle = ({
  offer,
  isSticky,
  ensName,
  className,
  isSingleItem,
}: {
  offer: EnsuranceOffer | null;
  ensName: string;
  isSticky: boolean;
  className?: string;
  isSingleItem: boolean;
}) => {
  const { classes } = useCompareHeaderStyles({ isSticky });

  const ensBaseinfoMap = useAppSelector((state) => state.ensCompare.ensuranceBaseInfo);

  const externCaclNotice = useExternCalcNotice(offer, false);

  return (
    <Card
      h={'100%'}
      className={`${classes.cardMiddle} ${className}`}
      py={0}>
      <Flex
        direction='column'
        gap='xs'
        h='100%'>
        {offer?.voucherTotalEuroCent && (
          <Text
            ta='end'
            c='primary'>
            <EnsIcon
              icon='FaGift'
              useAppColor
            />
            &nbsp;Wir schenken dir {EnsPriceInfo.once(offer.voucherTotalEuroCent).displayFormat()}&nbsp;€
          </Text>
        )}
        <Box>
          <Text
            className={classes.wrap}
            weight={700}
            ta={isSingleItem ? 'center' : 'right'}>
            {ensBaseinfoMap[ensName]?.displayName}
          </Text>
          {externCaclNotice}
        </Box>
      </Flex>
    </Card>
  );
};
