import { useAppSelector } from '@/hooks';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect } from 'react';

export const UserDataUpdateWatcher = (props: {
  requestFields?: string[];
  objectFields?: string[];
  onChange: () => void;
  debounce?: number;
}) => {
  const { onChange } = props;

  const userData = useAppSelector(
    (state) => state.userData,
    (a, b) => {
      for (const field of props?.requestFields || []) {
        if (a[field] !== b[field]) return false;
      }

      for (const field of props?.objectFields || []) {
        for (const bikeId of new Set([...Object.keys(a.bikes), ...Object.keys(b.bikes)])) {
          if (a.bikes[bikeId]?.[field] !== b.bikes[bikeId]?.[field]) return false;
        }
      }

      return true;
    }
  );

  const [debouncedUserData] = useDebouncedValue(userData, props.debounce || 100);

  useEffect(() => {
    onChange();
  }, [debouncedUserData, onChange]);

  return null;
};
