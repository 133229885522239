import { Stack, Title } from '@mantine/core';
import { ButtonLink } from '@unserkunde/enscompare-components/src/components/components';
import React from 'react';
import { EnsIcon } from '../shared/AvailalbeEnsIcons';
import { useAppSelector } from '@/hooks';

export default function ProductDocs() {
  const offerDocs = useAppSelector((state) => state.checkout.offer?.documents) || {};

  return (
    <Stack align='flex-start'>
      <Title order={5}>Produktinformationen</Title>
      {Object.entries(offerDocs).map(([key, value]) => (
        <ButtonLink
          leftIcon={
            <EnsIcon
              color='gray'
              icon='FiFile'
            />
          }
          key={key}
          as={'a'}
          href={value}
          target='_blank'>
          {key}
        </ButtonLink>
      ))}
    </Stack>
  );
}
