import React, { useMemo } from 'react';
import { Infobutton, frequentDisplayName } from '../shared/Common';
import { useAppSelector } from '@/hooks';
import { EnsuranceOffer } from '@/actions/types/EnsuranceOffer';

const OfferPriceHints = ({ offer }: { offer: EnsuranceOffer }) => {
  const voucherUnit = useAppSelector((state) => state.voucher?.voucher?.amount?.unit);
  const selectedPaymentPeriod = useAppSelector((state) => state.userData.paymentPeriod);

  const vst = offer?.vstProzent || '';
  return useMemo(() => {
    const content: any[] = ['Incl. ' + vst + '% VSt.'];

    if (offer?.period && offer?.period != selectedPaymentPeriod) {
      content.push(
        <React.Fragment key='paymentPeriodInfo'>
          {' '}
          Der Versicherer bietet die Zahlweise "{frequentDisplayName(selectedPaymentPeriod)}" nicht an. Bei diesem
          Versicherer bezahlst du {frequentDisplayName(offer?.period)}.
        </React.Fragment>
      );
    }

    if (voucherUnit) {
      content.push(
        <React.Fragment key={'voucher'}>
          <br />
          <br />
          {voucherUnit === 'percent' ? (
            <>
              Das prozentuale Cashback bezieht sich auf den Jahresbeitrag des von dir gewählten Vertrages. Solltest du
              also bspw. einen 50% Gutschein haben, so erhältst du die Hälfte des Jahresbeitrages des von dir gewählten
              Vertrages zurück. Nicht mit anderen Gutscheinen und Aktionen kombinierbar. Das Cashback wird zwei Monate
              nach Versicherungsbeginn, frühstens aber zum darauffolgenden 15. ausgezahlt, sofern der Erstbeitrag
              bezahlt und der Vertrag ungekündigt besteht und nicht widerrufen wurde. Solltest du nicht berechtigt sein,
              diesen Cashback-Gutscheincode einzulösen, da dieser bspw. Hersteller- oder Händlerabhängig ist, so
              behalten wir uns das Recht vor, das Cashback nicht auszuzahlen.
            </>
          ) : (
            <>
              Nicht mit anderen Gutscheinen und Aktionen kombinierbar. Das Cashback wird zwei Monate nach
              Versicherungsbeginn, frühstens aber zum darauffolgenden 15. ausgezahlt, sofern der Erstbeitrag bezahlt und
              der Vertrag ungekündigt besteht und nicht widerrufen wurde.
            </>
          )}
        </React.Fragment>
      );
    }

    return <>{content}</>;
  }, [vst, offer?.period, selectedPaymentPeriod, voucherUnit]);
};

export default OfferPriceHints;

export const EnsuranceInfoButton = ({ offer }) => {
  return <Infobutton text={<OfferPriceHints offer={offer} />} />;
};
