import React, { useMemo } from 'react';
import { OptionsGroup } from './EnsuranceListOptions';
import { Text, Anchor, Box, Group, Title, UnstyledButton, TextProps } from '@mantine/core';
import { EnsIcon } from '../shared/AvailalbeEnsIcons';
import { useAppSelector } from '@/hooks';

import WhatsAppButtonSource from './ContactBoxWhatsappButton.svg';

import isMobile from 'is-mobile';
import { useIsXs } from '@/forms/shared/EnsContainerObserver';

const usePrettyVisitorId = () => {
  const visitorId = useAppSelector((state) => state.visitorId.id);

  return (
    useMemo(() => {
      if (!visitorId) return null;

      return visitorId.match(/.{1,2}/g).join('-');
    }, [visitorId]) || ''
  );
};

const PhoneContact = ({}) => {
  const number = useAppSelector((state) => state.ensfields.naming_contactPhone);

  return (
    <span>
      <EnsIcon
        useAppColor
        icon='FaPhoneAlt'
      />{' '}
      <Anchor href={'tel:' + number}>{number}</Anchor>
    </span>
  );
};

const MailContact = () => {
  const mail = useAppSelector((state) => state.ensfields.naming_contactEmail);

  return (
    <span>
      <EnsIcon
        useAppColor
        icon='FaEnvelope'
      />{' '}
      <Anchor href={'mailto:' + mail}>{mail}</Anchor>
    </span>
  );
};

export const InfotextBox = (props: { textProp?: TextProps }) => {
  const displayId = usePrettyVisitorId();

  return (
    <Text
      ta='end'
      {...(props.textProp || {})}>
      Angebots-ID:{' '}
      <Box
        component='span'
        fw='bold'
        c='primary'>
        {displayId}
      </Box>
    </Text>
  );
};

const WhatsAppButton = () => {
  const number = useAppSelector((state) => state.ensfields.naming_contactPhone) || '';

  const waNumber = useMemo(() => {
    return number.replace(/^0/, '+49').replace(/\D/g, '');
  }, [number]);

  const deviceIsMobile = useMemo(() => isMobile(), []);

  const visitorId = usePrettyVisitorId();
  const messageText = 'Guten Tag, meine Angebots ID lautet: ' + visitorId;

  if (!deviceIsMobile) return null;

  return (
    <Anchor href={' https://wa.me/' + waNumber + '?text=' + encodeURIComponent(messageText)}>
      <UnstyledButton
        component='img'
        mah={'xl'}
        src={WhatsAppButtonSource}
        xmlns='http://www.w3.org/2000/svg'
      />
    </Anchor>
  );
};

export const ContactBoxSmall = () => {
  const instanceMode = useAppSelector((state) => state.ensfields?.ens_instance_mode);
  const isXs = useIsXs();

  if (instanceMode !== 'bike' && instanceMode !== 'animal') return null;

  return (
    <Box
      p={'sm'}
      style={{ flexGrow: 1 }}>
      <Group
        position='right'
        align='center'>
        <MailContact />
        <PhoneContact />
        {!isXs && <WhatsAppButton />}
      </Group>
      <Group
        mt='xs'
        position='right'
        align='top'>
        <InfotextBox />
        {isXs && <WhatsAppButton />}
      </Group>
    </Box>
  );
};
