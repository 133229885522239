import React, { useCallback } from 'react';

import { ButtonLink, Button } from '@unserkunde/enscompare-components/src';
import { Grid, createStyles, Space, Container, Notification } from '@mantine/core';

import { FaArrowRight } from 'react-icons/fa';
import { addSecondBike, confirmEnsuranceOverview } from '../../actions/progress';
import { useInsuranceObjectName } from '../shared/InstanceNaming';
import { EntryQuestionContainer } from '../shared/DynamicEntryQuestion';
import OverviewInput from './OverviewInput';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { EntryQuestionSaver, resetSavedState, useSaverIsActive } from '@/features/EntryQuestionSaver';
import { useBrowsersBack } from '@/features/BrowserNavigationHandler';
import { getQueryStrings } from '@/actions/shared/QueryParams';
import {
  hasSavedFilterValues as getHasSavedFilterValues,
  resetFilterValues,
} from '@/actions/customFilter/customFilterSaver';
import { getUserInputEventDebugInfo } from '@/actions/form';
import { EnsIcon } from '../shared/AvailalbeEnsIcons';
import { ProceduralOverviewInput, useIsProcedural } from './proceduralOverviewInput/ProceduralOverviewInput';
import { useGlobalBackAction } from '@/processes/shared/GlobalBackContext';

export const useEnsOverviewStyles = createStyles((theme) => ({
  page: {
    paddingBottom: theme.spacing.xl,
  },
  topButtonRow: {
    marginTop: theme.spacing.sm,
    marginBottom: theme.spacing.xl,
    padding: '0',
  },
}));

const useBackEvent = () => {
  const dispatch = useAppDispatch();
  return useCallback(
    () => dispatch({ type: 'DEVICEMODE_SELECTED', devkey: '', ...getUserInputEventDebugInfo() }),
    [dispatch]
  );
};

function TopButtonRow() {
  const onResetDeviceMode = useBackEvent();
  const { classes } = useEnsOverviewStyles();

  const canSelectDeviceMode = useAppSelector((state) => state.devicemode.isselect);

  const hasGlobalBackButton = useGlobalBackAction(onResetDeviceMode);

  if (!canSelectDeviceMode || hasGlobalBackButton) return <Space h='lg' />;

  return (
    <Container className={classes.topButtonRow}>
      <ButtonLink
        leftIcon={
          <EnsIcon
            icon='FaArrowLeft'
            useAppColor={true}
          />
        }
        onClick={onResetDeviceMode}>
        Zurück
      </ButtonLink>
    </Container>
  );
}

function LoadedStateIndicator() {
  const hasLoadedEntryQuestions = useAppSelector((state) => state.userData.___savedEntryQuestionLoaded);
  const hasSavedFilterValues = useAppSelector(getHasSavedFilterValues);
  const dispatch = useAppDispatch();

  const presetName = useAppSelector((state) => state.userData.presetName);

  const onReset = useCallback(() => {
    dispatch((_, getState: RootStateGetter) => {
      resetSavedState(presetName, getState());
    });

    dispatch(resetFilterValues());
    dispatch({ type: 'RESET_SAVED_STATE' });
  }, [presetName]);

  const isDisabled = !useSaverIsActive();
  if (isDisabled) return null;

  return (
    <>
      {(hasLoadedEntryQuestions || hasSavedFilterValues) && (
        <EntryQuestionContainer>
          <Notification
            title='Vorherige Eingabe gefunden'
            onClose={onReset}>
            Wir haben die Daten Ihrer letzten Eingabe erneut geladen. Um dies zurückzusetzen drücken Sie auf das
            &quot;X&quot;
          </Notification>
        </EntryQuestionContainer>
      )}
    </>
  );
}

export const EntryQuestionButtonLabel = 'Angebote ansehen';

function ButtomButtonRow() {
  const dispatch = useAppDispatch();
  const onAddSecondBike = useCallback(() => dispatch(addSecondBike()), [dispatch]);
  const onReloadEnsuranceList = useCallback(() => dispatch(confirmEnsuranceOverview()), [dispatch]);

  const hasSecondBike = useAppSelector((state) => Object.keys(state.userData.bikes).length > 1);

  const insuranceObjectName = useInsuranceObjectName();

  return (
    <Container
      px={0}
      data-testid='ens-default-overview-questions'>
      <Grid>
        <Grid.Col
          xs={12}
          sm={6}>
          <Button
            fullWidth
            variant='outline'
            onClick={onAddSecondBike}>
            Zweites {insuranceObjectName} {hasSecondBike ? 'anzeigen' : 'hinzufügen'}
          </Button>
        </Grid.Col>
        <Grid.Col
          xs={12}
          sm={6}>
          <Button
            color='secondary'
            fullWidth
            data-testid='btn-ens-next'
            onClick={onReloadEnsuranceList}>
            {EntryQuestionButtonLabel}&nbsp;&nbsp;
            <FaArrowRight color='white' />
          </Button>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

const EnsuranceOverview = () => {
  const { classes } = useEnsOverviewStyles();

  const onResetDeviceMode = useBackEvent();
  useBrowsersBack(onResetDeviceMode);

  const isProceduralProgress = useIsProcedural();

  return (
    <Container
      fluid
      className={classes.page}>
      {!isProceduralProgress ? <TopButtonRow /> : <Space h='lg' />}
      <LoadedStateIndicator />
      <Container px={0}>
        {!isProceduralProgress ? <OverviewInput /> : <ProceduralOverviewInput onBack={onResetDeviceMode} />}
      </Container>
      {!isProceduralProgress && <ButtomButtonRow />}
      {getQueryStrings().ensShowRerender}
      <EntryQuestionSaver />
    </Container>
  );
};

export default EnsuranceOverview;
