import { EnsuranceOffer } from '@/actions/types/EnsuranceOffer';
import { useCompareHeaderStyles } from './CompareTableHeaderStyles';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useCallback } from 'react';
import { ensuranceSelected } from '@/actions/progress';
import { Box, Button, Card, Flex, Text, Tooltip } from '@mantine/core';
import React from 'react';
import { EnsIcon } from '@/forms/shared/AvailalbeEnsIcons';
import { EnsPriceInfo } from '@/forms/shared/pricing/EnsPriceInfo';
import { InnerSizeObserver, useGlobalSizeObserverRef, useObservedSize } from '@/forms/shared/InnerSizeObserver';
import { FiArrowRight } from 'react-icons/fi';

export const CompareTableHeaderItemBottom = ({
  offer,
  isSticky,
  className,
}: {
  offer: EnsuranceOffer | null;
  ensName: string;
  isSticky: boolean;
  className?: string;
  isSingleItem: boolean;
}) => {
  const { classes } = useCompareHeaderStyles({ isSticky });

  const dispatch = useAppDispatch();
  const onEnsuranceSelected = useCallback(() => dispatch(ensuranceSelected(offer?.name)), [dispatch, offer?.name]);

  const offerIsValid = offer && !offer.failed;

  return (
    <>
      <Card
        className={`${classes.cardBottom} ${className}`}
        style={{}}
        pt={0}>
        <Flex
          direction='column'
          gap='xs'
          pt='xs'
          h='100%'>
          <Tooltip
            multiline={true}
            label='Bitte erst die Daten oben passend ausfüllen'
            position='bottom'
            disabled={offerIsValid}>
            <Box>
              <InnerSizeObserver
                render={(size, ref) => {
                  const isLargeButton = !size || size.width > 100;
                  return (
                    <Button
                      ref={ref}
                      disabled={!offerIsValid}
                      fullWidth
                      compact
                      color='secondary'
                      rightIcon={!isLargeButton ? null : <FiArrowRight color='white' />}
                      onClick={onEnsuranceSelected}
                      size='md'>
                      {isLargeButton ? (
                        'Beantragen'
                      ) : (
                        <EnsIcon
                          color='white'
                          icon='FaShoppingCart'
                        />
                      )}
                    </Button>
                  );
                }}
              />
            </Box>
          </Tooltip>
        </Flex>
      </Card>
    </>
  );
};
