import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, Box, Anchor, Space } from '@mantine/core';

import { useAppSelector, useDynamicFields } from '../../hooks';
import DynamicEntryQuestion, { EntryQuestionContainer } from '../shared/DynamicEntryQuestion';
import OverviewBikeInput from './OverviewBikeInput';
import { EnsAgbControl } from './EnsAgbControl';
import { EntryQuestionButtonLabel } from './EnsuranceOverview';
import { DynamicEntryQuestionType } from '@/actions/types/DynamicEntryQuestionType';

const OverEntryQuestionMore = () => {
  const entryQuestionsMore = useDynamicFields('entryQuestionsMore');

  const [visibleQuestion, setVisibleQuestion] = useState<Record<string, boolean>>({});
  const [initilizing, setInitilizing] = useState(true);

  const onQuestionVisibilityChange = useCallback((setting: DynamicEntryQuestionType, visible: boolean) => {
    setVisibleQuestion((current) => ({ ...current, [setting.name]: visible }));
  }, []);

  const showMoreQuestion = useMemo(() => {
    return Object.values(visibleQuestion || {}).some((v) => v);
  }, [visibleQuestion]);

  useEffect(() => {
    setInitilizing(false);
  }, []);

  if (!showMoreQuestion && !initilizing) return null;

  return (
    <>
      <Accordion
        unstyled
        styles={{
          item: {},
          chevron: {
            display: 'none',
          },
          control: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
          },
        }}
        chevronPosition='right'>
        <Accordion.Item value='showMoreEntryQuestions'>
          <Accordion.Control>
            <Anchor>Rabattrelevante Fragen anzeigen</Anchor>
          </Accordion.Control>
          <Accordion.Panel>
            {entryQuestionsMore.map((eo, i) => (
              <DynamicEntryQuestion
                key={i}
                setting={eo}
                visibilityChange={onQuestionVisibilityChange}
              />
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <Space h='lg' />
    </>
  );
};

function OverviewInput() {
  const defaultBikeId = useAppSelector((state) => state.userData.defaultBikeId);

  const entryQuestions = useDynamicFields('entryQuestions');
  const entryQuestionsTop = useDynamicFields('entryQuestionsTop');

  const hasSecondBike = useAppSelector((state) => Object.keys(state.userData.bikes).length > 1);

  return (
    <Box>
      <OverviewBikeInput
        bikeId={defaultBikeId}
        bikeIndex={hasSecondBike ? 1 : null}
        fieldsPosition='objectEntryQuestionsTop'
      />

      <EntryQuestionContainer>
        {entryQuestionsTop.map((eo, i) => (
          <DynamicEntryQuestion
            key={i}
            setting={eo}
          />
        ))}
      </EntryQuestionContainer>

      <OverviewBikeInput
        bikeId={defaultBikeId}
        fieldsPosition='objectEntryQuestions'
      />

      <EntryQuestionContainer>
        {entryQuestions.map((eo, i) => (
          <DynamicEntryQuestion
            key={i}
            setting={eo}
          />
        ))}
      </EntryQuestionContainer>

      <OverEntryQuestionMore />

      <EntryQuestionContainer>
        <EnsAgbControl buttonLabel={EntryQuestionButtonLabel} />
      </EntryQuestionContainer>
    </Box>
  );
}

export default React.memo(OverviewInput);
