import { getUrl } from '../data';
import { TrackerType } from './TrackerType';
import debounce from 'debounce';

let buffer = [];
let storedVisitorId = null;

const submitBuffer = debounce((dispatch?: AppDispatch) => {
  dispatch((_, getState: RootStateGetter) => {
    const visitorId = getState().visitorId.id;

    const submitActions = buffer.map((item) => ({
      objectId: visitorId,
      objectType: 'visitorId',
      action: 'frontend-tracking:' + item.name,
    }));

    //sendBeacon is better than fetch cause it works even after page close
    navigator.sendBeacon(getUrl('/api/ens/v1/stats/track/batch'), JSON.stringify(submitActions));

    buffer = [];
  });
}, 7500);

window.addEventListener('beforeunload', function (e) {
  submitBuffer.flush();
});

export const LocalPageTracking: TrackerType = (trackingObj, dispatch) => {
  if (!storedVisitorId) {
    dispatch((_, getState: RootStateGetter) => {
      storedVisitorId = getState()?.visitorId?.id;
    });
  }

  buffer.push({ ...trackingObj });
  submitBuffer(dispatch);
};
