import { useRef } from 'react';
import { TrackerEventType } from './TrackerType';
import { track } from './index';
import { useDispatchEffect } from '@/hooks';

export const PageTracking = (props: TrackerEventType) => {
  const wasTracked = useRef(false);

  useDispatchEffect((dispatch) => {
    if (wasTracked.current) return;
    dispatch(track(props));
    wasTracked.current = true;
  }, []);

  return null;
};
