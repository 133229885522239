import React, { useCallback, useEffect, useMemo, useState } from 'react';

type GlobalProgressSource = 'prozeduralEntryQuestionsRoot';

type GlobalProgressContextType = {
  data: {
    [key in GlobalProgressSource]?: {
      progress: number;
      total: number;
      percentage: number;
    };
  };
  setProgress: (source: GlobalProgressSource, progress: number, total: number) => void;
  resetProgress: (source: GlobalProgressSource) => void;
};

export const GlobalProgressContext = React.createContext<GlobalProgressContextType>(null);

export const useProgressReporting = (source: GlobalProgressSource, progress: number, total: number) => {
  const context = React.useContext(GlobalProgressContext);
  useEffect(() => {
    context.setProgress(source, progress, total);
    return () => context.resetProgress(source);
  }, [progress, total]);
};

export const useGlobalProgress = () => React.useContext(GlobalProgressContext).data;

export const GlobalProgressProvider = (props: React.PropsWithChildren) => {
  const [data, setData] = useState<GlobalProgressContextType['data']>({});

  const setProgress = useCallback((source: GlobalProgressSource, progress: number, total: number) => {
    setData((oldData) => ({
      ...oldData,
      [source]: { progress, total, percentage: !total ? 0 : (progress / total) * 100 },
    }));
  }, []);

  const resetProgress = useCallback((source: GlobalProgressSource) => {
    setData((oldData) => ({ ...oldData, [source]: undefined }));
  }, []);

  const context: GlobalProgressContextType = useMemo(
    () => ({
      setProgress,
      resetProgress,
      data,
    }),
    [data, setProgress, resetProgress]
  );

  return <GlobalProgressContext.Provider value={context}>{props.children}</GlobalProgressContext.Provider>;
};
