// import './wdyr';
import React, { useMemo } from 'react';
import * as ReactIcons from 'react-icons';
import { createStyles } from '@mantine/core';

import { Provider } from 'react-redux';
import Router from './Router';
import Predef from './features/Predef';
import Preset from './features/Preset';
import Ensfields from './features/Ensfields';
import RefererPing from './features/RefererPing';
import AutoloadEns from './features/AutoloadEns';
import ImpressumControl from './forms/shared/impressumControl';
import BrowserCheck from './features/Browsercheck';
import GlobalErrorHandler from './features/GlobalErrorHandler';
import { selectorIsInitialLoading, useAppSelector } from './hooks';
import Initializer from './features/Initializer';
import EnsThemeProvider from './EnsThemeProvider';
import EnsLoadingOverlay from './forms/shared/loading/EnsLoadingOverlay';
import { CustomCss } from './features/CustomCss';
import Tracking from '@/actions/tracking/Tracking';
import DevmodeDisplay from './features/DevmodeDisplay';
import { GetIsDevMode } from './forms/shared/IsDevMode';
import { BrowserNavigationHandler } from './features/BrowserNavigationHandler';
import { AutoloadStandaloneFeature } from './features/AutoloadStandaloneFeature';
import { DefaultCustomProducts } from './features/DefaultCustomProducts';
import { EnsContainerSizeObserverProvider } from './forms/shared/EnsContainerObserver';
import { UncompletedOrderSaver } from './features/UncompletedOrderSaver';
import { EnsVisitorId } from './features/EnsVisitorId';
import { AppInViewObserver } from './forms/shared/AppInViewObserver';
import { SentrySetting } from './sentry';
import { OpenIframeResize } from './features/OpenIframeResize';
import { GlobalBackProvider } from './processes/shared/GlobalBackContext';
import { SizeObserverContextProvider } from './forms/shared/InnerSizeObserver';
import { AppStoreType } from './store';
import { useEnsOptions } from './reducer/ensOptions';
import { GlobalProgressProvider } from '@/processes/shared/GlobalProgressContext';
import { CustomProductQuestionUpdateWatcher } from './forms/shared/dynamicEntryQuestion/components/CustomProductOptInQuestion';

const useAppStyle = createStyles((theme, props: { ensOptions: EnsOptions }) => {
  return {
    fullscreen: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999999,
      overflowY: 'auto',
      backgroundColor: 'white',
    },
    wrapper: {
      maxWidth: props.ensOptions?.fullWidth ? '100% !important' : '1170px !important',
      //backgroundColor: 'white'
    },
  };
});

function AppContent() {
  const isInitialLoading = useAppSelector(selectorIsInitialLoading);

  const iconContext = useMemo(() => ({ color: '#A4D0F8', className: 'react-icons' }), []);
  const ensOptions = useEnsOptions();
  const { classes } = useAppStyle({ ensOptions });

  const Renderer = useMemo(
    () =>
      !ensOptions?.isFullscreen
        ? ({ children }) => children
        : ({ children }) => <div className={classes.fullscreen}>{children}</div>,
    [classes.fullscreen, ensOptions?.isFullscreen]
  );

  return (
    <ReactIcons.IconContext.Provider value={iconContext}>
      <SizeObserverContextProvider>
        <GlobalBackProvider>
          <GlobalProgressProvider>
            <Renderer>
              <AppInViewObserver>
                <div className={`ens_app_root ${classes.wrapper}`}>
                  <EnsContainerSizeObserverProvider>
                    <CustomCss />
                    {GetIsDevMode() && <DevmodeDisplay />}
                    <BrowserCheck />
                    <Initializer />
                    <Router />
                    <Predef />
                    <Preset />
                    <UncompletedOrderSaver />
                    <Ensfields />
                    <Tracking />
                    <GlobalErrorHandler />
                    <AutoloadEns />
                    <CustomProductQuestionUpdateWatcher />
                    <EnsVisitorId />
                    <DefaultCustomProducts />
                    <AutoloadStandaloneFeature />
                    <RefererPing />
                    <BrowserNavigationHandler />
                    <SentrySetting />
                    <OpenIframeResize />
                    {isInitialLoading ? null : <ImpressumControl />}
                    <EnsLoadingOverlay />
                  </EnsContainerSizeObserverProvider>
                </div>
              </AppInViewObserver>
            </Renderer>
          </GlobalProgressProvider>
        </GlobalBackProvider>
      </SizeObserverContextProvider>
    </ReactIcons.IconContext.Provider>
  );
}

export function App({ mantineProviderProps = null }) {
  return (
    <EnsThemeProvider {...mantineProviderProps}>
      <AppContent />
    </EnsThemeProvider>
  );
}

function AppWithStore(props: { mantineProviderProps?: any; store: AppStoreType }) {
  return (
    <Provider
      store={props.store}
      noopCheck='never'>
      <App mantineProviderProps={props.mantineProviderProps} />
    </Provider>
  );
}

export default AppWithStore;
