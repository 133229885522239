import { DynamicEntryQuestionType } from '@/actions/types/DynamicEntryQuestionType';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEffect, useMemo, useState } from 'react';
import { BikeUserDataType, dynamicObjectStartValue } from '@/reducer/userData';
import { GetIsDevMode } from '../IsDevMode';
import { changeUserInput } from '@/actions/form';

export const parseCustomSettings = (settingsText: string) => {
  const lines = (settingsText || '').split('\r\n');
  const settings = lines.map((l) => l.split('=')).filter((o) => o.length === 2);
  return settings.reduce((prev, current) => ({ ...prev, [current[0]]: current[1] }), {});
};

export const useCustomSettings = (settingsText) => {
  return useMemo(() => parseCustomSettings(settingsText), [settingsText]);
};

export const useValueList = (valueText, reverse = false) => {
  return useMemo(() => {
    const result = valueText.split(/\r?\n/);
    let values = result.map((s) => s.split(':'));

    if (reverse) values = values.map((v) => v.reverse());

    return values;
  }, [valueText]);
};

export const useStartValue = (enabled: boolean, setting: DynamicEntryQuestionType, bikeId: string | null) => {
  const [initilized, setInitilized] = useState(false);
  const dispatch = useAppDispatch();
  const currentValue = useAppSelector((state) =>
    bikeId && state.userData.bikes ? state.userData.bikes?.[bikeId]?.[setting.name] : state.userData[setting.name]
  );

  useEffect(() => {
    if (initilized || !enabled) return;

    const startSource = setting.positionType === 'object' ? dynamicObjectStartValue : {};
    if (!startSource[setting.name] || currentValue) {
      setInitilized(true);
      return;
    }

    const startValue = startSource[setting.name];

    if (GetIsDevMode()) console.log('Loading dynamic start value for ' + setting.name + ' with', startValue);

    if (bikeId) dispatch(changeUserInput(setting.name as keyof BikeUserDataType, startValue, bikeId));
    else dispatch(changeUserInput(setting.name as keyof UserDataType, startValue));

    setInitilized(true);
  }, [enabled, initilized, setting, bikeId, currentValue, dispatch]);
};
